<template>
  <div id="content" class="enter-address">
    <b-container id="step-3" style="background:#000028;color:white;">
      <b-row class="mt-5 pt-5 mb-5">

        <b-col>

        </b-col>
      </b-row>
      <b-row class="mb-5 pb-5 ">
        <b-col class="mb-5 pb-5 mt-5 pt-5">
          <h2 class="text-center mt-2 pt-2">Vielen Dank für Ihre Anfrage!</h2>
          <p class="text-center">Wir werden uns in Kürze bei Ihnen melden.</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'SuccessPage',
  data: function () {
    return {}
  },
  mounted() {
    this.resetState()
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'orderItems',
    ])
  },
  methods: {
    ...mapActions([
      'resetState'
    ]),
  }
}
</script>
