<template>
  <div id="content" class="enter-address">
    <b-container id="step-3" style="background:#000028;color:white;">
      <b-row class="mt-5 pt-5 mb-5">

        <b-col>
          <div class="headline">
            <h5>Adresse angeben </h5>
            <h3>Bitte füllen Sie alle erforderlichen Felder aus</h3>
          </div>
          <p>
            Wir benötigen die folgenden Daten um Ihre Anfrage abzuschließen.
          </p>
        </b-col>
      </b-row>


      <b-row class=" mb-5 pb-5">
        <b-col>
          <b-form-group

              class="floating"
              label-for="input-company"
              required
          >
            <b-form-input id="input-company"
                          v-model="form.company"
                          :state="validation.company"
                          class="border-top-0 border-right-0 border-left-0"
                          inline
                          placeholder="Firma"
                          required
                          type="text"
                          @change="checkError('company')"
            ></b-form-input>
            <label for="input-company">Firma</label>
            <b-form-invalid-feedback :state="validation.company">
              Bitte geben Sie ein Unternehmen an.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-row>
            <b-col>
              <b-form-group
                  class="floating"
                  required
              >
                <b-form-input
                    id="input-firstName"
                    v-model="form.firstName"
                    :state="validation.firstName"
                    class="border-top-0 border-right-0 border-left-0"
                    placeholder="Vorname"
                    required
                    type="text"
                    @change="checkError('firstName')"
                ></b-form-input>
                <label for="input-firstName">Vorname</label>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="floating"
                            required
              >
                <b-form-input
                    id="input-lastName"
                    v-model="form.lastName"
                    :state="validation.lastName"
                    class="border-top-0 border-right-0 border-left-0"
                    placeholder="Nachname"
                    required
                    type="text"
                    @change="checkError('lastName')"
                ></b-form-input>
                <label for="input-lastName">Nachname</label>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="8">
              <b-form-group class="floating"
                            required
              >
                <b-form-input id="input-street"
                              v-model="form.street"
                              :state="validation.street"
                              class="border-top-0 border-right-0 border-left-0"
                              placeholder="Straße"
                              required
                              type="text"
                              @change="checkError('street')"
                ></b-form-input>
                <label for="input-street">Straße</label>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group class="floating"
                            required
              >
                <b-form-input
                    id="input-houseNr"
                    v-model="form.houseNr"
                    :state="validation.houseNr"
                    class="border-top-0 border-right-0 border-left-0"
                    placeholder="Hausnummer"
                    required
                    type="text"
                    @change="checkError('houseNr')"
                ></b-form-input>
                <label for="input-houseNr">Hausnummer</label>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group class="floating"
                            required
              >
                <b-form-input
                    id="input-zip"
                    v-model="form.zip"
                    :state="validation.zip"
                    class="border-top-0 border-right-0 border-left-0"
                    placeholder="PLZ"
                    required
                    type="text"
                    @change="checkError('zip')"
                ></b-form-input>
                <label for="input-zip">PLZ</label>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="floating"
                            required
              >
                <b-form-input
                    id="input-city"
                    v-model="form.city"
                    :state="validation.city"
                    class="border-top-0 border-right-0 border-left-0"
                    placeholder="Stadt"
                    required
                    type="text"
                    @change="checkError('city')"
                ></b-form-input>
                <label for="input-city">Stadt</label>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm>
              <b-form-group
                  class="floating" required
              >
                <b-form-input id="input-email"
                              v-model="form.email"
                              :state="validation.email"
                              class="border-top-0 border-right-0 border-left-0"
                              inline
                              placeholder="E-Mail"
                              required
                              type="text"
                              @change="checkError('email')"
                ></b-form-input>
                <label for="input-email">E-Mail</label>
              </b-form-group>
            </b-col>
            <b-col sm>
              <b-form-group
                  class="floating"
              >
                <b-form-input id="input-telephone"
                              v-model="form.telephone"
                              :state="validation.telephone"
                              class="border-top-0 border-right-0 border-left-0"
                              inline
                              placeholder="Telefon"
                              required
                              type="text"
                              @change="checkError('telephone')"
                ></b-form-input>
                <label for="input-telephone">Telefon</label>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-checkbox v-model="form.terms"
                           :state="validation.terms"
                           class="mb-2 mt-4 mr-sm-2 mb-sm-0"
                           @change="checkError('terms')"
          >Ich habe die Nutzungsbedingungen für die
            Serviceeinrichtung
            (<a href="https://assets.new.siemens.com/siemens/assets/api/uuid:3ddabf3b-adff-40d5-ab65-a3c81a4ed7e3/rsc-dortmund-eving-nutzungsbedingungenallgemeinerteil.pdf"
                target="_blank">allgemeiner Teil</a> und <a
                href="https://assets.new.siemens.com/siemens/assets/api/uuid:cd89d5f3-09d8-4400-a2e5-01e94b617a5a/rsc-dortmund-eving-nutzungsbedingungenbesondererteil.pdf"
                target="_blank">besonderer Teil</a>)
            zur
            Kenntnis genommen. Ich habe die <a
                href="https://assets.new.siemens.com/siemens/assets/api/uuid:d471e0ff2fe6eeac7106f95afcc47d6c25e31825/bedingungen-fuer-das-servicegeschaeft-mo-cs.pdf"
                target="_blank">Bedingungen
              für das Servicegeschäft MO CS</a> zur Kenntnis genommen. Ich habe den <a
                href="https://assets.new.siemens.com/siemens/assets/api/uuid:70a6dbb2-ee3c-4082-b942-4137f81a7d2f/2022-rsc-do-eving-leistungskatalog.pdf"
                target="_blank">Leistungskatalog</a> zur Kenntnis
            genommen.
          </b-form-checkbox>

          <b-form-checkbox v-model="form.privacy"
                           :state="validation.privacy"
                           class="mt-4 mb-2 mr-sm-2 mb-sm-0"
                           @change="checkError('privacy')"
          >Hiermit bestätige ich die Kenntnisnahme der
            <a href="https://www.mobility.siemens.com/global/de/allgemein/datenschutz.html"
               target="_blank">Datenschutzbestimmungen</a></b-form-checkbox>
          <div class="mt-4">
            <div>
              Weitere Informationen finden Sie auf der Webseite des <a
                href="https://www.mobility.siemens.com/global/de/portfolio/schiene/services/maintenance-services/rail-service-center-dortmund.html"
                target="_blank">Rail Service Center Dortmund-Eving</a>.
            </div>
            <b-button class="float-left mt-5" variant="outline-primary" @click="$router.push('/choose-date')">Zurück
            </b-button>
            <b-button
                class="float-right mt-5"
                variant="primary" @click="enterAddress">
              Absenden
            </b-button>
          </div>
        </b-col>
      </b-row>


    </b-container>
    <b-container>

    </b-container>
  </div>
</template>

<script>

import {mapMutations} from "vuex"
import {mapGetters} from 'vuex'

export default {
  name: 'EnterAdress',
  data: function () {
    return {
      form: {
        company: "",
        firstName: "",
        lastName: "",
        street: "",
        houseNr: "",
        zip: "",
        city: "",
        telephone: "",
        email: "",
        privacy: false,
        terms: false
      },
      validation: {
        company: null,
        firstName: null,
        lastName: null,
        street: null,
        houseNr: null,
        zip: null,
        city: null,
        telephone: null,
        email: null,
        terms: null,
        privacy: null
      },
      submitted: false,
      hasErrors: false
    }
  },
  methods: {
    ...mapMutations([
      'setClient',
    ]),
    checkError(field) {
      if (field === 'houseNr') {
        if (this.form[field].length <= 0) {
          this.validation[field] = false;
          this.hasErrors = true;
        } else {
          this.validation[field] = true;
        }
      } else if (field === 'privacy' || field === 'terms') {
        if (!this.form[field]) {
          this.validation[field] = false;
          this.hasErrors = true;
        } else {
          this.validation[field] = true;
        }
      } else {
        if (this.form[field].length < 3) {
          this.validation[field] = false;
          this.hasErrors = true;
        } else {
          this.validation[field] = true;
        }
      }
    },
    checkErrors() {
      this.hasErrors = false;
      this.checkError('company')
      this.checkError('firstName')
      this.checkError('lastName')
      this.checkError('street')
      this.checkError('houseNr')
      this.checkError('city')
      this.checkError('zip')
      this.checkError('email')
      this.checkError('privacy')
      this.checkError('telephone')
      this.checkError('terms')
    },
    async enterAddress() {
      this.checkErrors()
      if (!this.hasErrors) {
        this.setClient(this.form)
        await this.$store.dispatch("submitRequest").then(data => {

          if (data._id.length > 0) {
            this.$router.push("/success")
          }
        });


      }
    }
  },
  computed: {
    ...mapGetters(['orderItems'])
  }
}
</script>
<style lang="scss">
.hero-wrapper {
  min-height: 500px;
  background: url("../assets/header.jpg") no-repeat center center;
  background-size: cover;
}

.custom-control-input.is-valid ~ .custom-control-label {
  color: inherit;
}
</style>
